import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './state';
import rootSaga from './rootSaga';

function configureStore() {
  const middleware = [];
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware({});

  middleware.push(sagaMiddleware);

  enhancers.push(applyMiddleware(...middleware));
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer, composeEnhancers(...enhancers),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

const store = configureStore();

export default store;
