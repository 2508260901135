import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AUTH } from '../../redux/state';
import {
  dividerGray,
  errorRed,
  inputDarkGray,
  neuBlue,
  white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  containerAbout: {
    color: 'red',
  },

  backgroundImage: {
    backgroundImage: 'url("../images/login4.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '50vh',
    position: 'absolute',
  },

  formContainer: {
    position: 'relative',
    width: '550px',
    height: '80vh',
    background: `${white}`,
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
    top: '10vh ',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1.3',
  },

  title: {
    color: '#00509b',
    fontFamily: 'QuicksandMedium',
    textAlign: 'center',
  },

  titleLine: {
    width: '70px',
    height: '4px',
    background: '#00509b',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '25px',
    borderRadius: '2px',
  },

  form: {
    width: '60%',
    margin: '0 auto',
    flex: 2,
  },

  loginLabel: {
    fontFamily: 'QuicksandRegular',
    color: 'grey',
    fontSize: '14px',
    margin: '0',
    marginLeft: '10px',
  },

  loginInput: {
    fontFamily: 'QuicksandRegular',
    fontSize: '14px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    width: '100%',
    border: '0',
    borderBottom: `solid 2px ${dividerGray}`,
    color: `${inputDarkGray}`,
    marginTop: '5px',
    outline: 'none',
  },

  passwordInput: {
    marginTop: '30px',
  },

  loginButton: {
    fontFamily: 'QuicksandRegular',
    fontSize: '16px',
    border: 'none',
    borderRadius: '25px',
    color: `${white}`,
    padding: '12px',
    textAlign: 'center',
    width: '180px',
    marginTop: '75px',
    cursor: 'pointer',
    backgroundColor: `${neuBlue}`,
  },

  loginErrorContainer: {
    position: 'relative',
  },

  loginError: {
    color: `${errorRed}`,
    fontSize: '14px',
    fontFamily: 'QuicksandRegular',
    position: 'absolute',
    top: '15px',
    marginLeft: '10px',
    display: ({ error }) => (error ? 'block' : 'none'),
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

}));

const ChangePassword = ({
  password,
  onUpdateField,
  error,
  onPasswordChange,
  user,
}) => {
  const classes = useStyles({ error });

  const handlePasswordChange = (e) => {
    onUpdateField(AUTH, 'resetPassword', e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    onUpdateField(AUTH, 'resetConfirmPassword', e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordChange(user, password);
  };

  return (
    <div>
      <div className={classes.backgroundImage} />
      <div className={classes.formContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>Job Manager</h1>
          <div className={classes.titleLine} />
        </div>
        <form className={classes.form} autoComplete="on" onSubmit={handleSubmit}>
          <div>
            <label className={classes.loginLabel}>
              New Password
              <input
                name="password"
                autoComplete="off"
                type="password"
                className={classes.loginInput}
                onChange={handlePasswordChange}
              />
            </label>
          </div>
          <div className={classes.passwordInput}>
            <label className={classes.loginLabel}>
              Confirm New Password
              <input
                name="confirm-password"
                autoComplete="off"
                type="password"
                className={classes.loginInput}
                onChange={handleConfirmPasswordChange}
              />
            </label>
          </div>
          <div className={classes.loginErrorContainer}>
            <span className={classes.loginError}>{error}</span>
          </div>
          <div className={classes.buttonContainer}>
            <button type="submit" className={classes.loginButton}>LOG IN</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
