import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  buttonRed, buttonRedHover, inactiveGray, lightGray, neuBlue, white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  jobRow: {
    marginTop: '50px',
  },
  jobRowInactive: {
    '& p': {
      color: `${inactiveGray}`,
    },
  },
  jobLineInactive: {
    background: `${inactiveGray} !important`,
  },

  titleContainer: {
    display: 'inline-block',
  },

  rowEntryTitle: {
    fontFamily: 'QuicksandBold',
    wordBreak: 'break-word',
  },
  rowTitleLine: {
    width: '50px',
    height: '3px',
    background: `${neuBlue}`,
    borderRadius: '1.5px',
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    '& input': {
      width: '15px',
      height: '15px',
    },
  },
  radioText: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  jobRowText: {
    marginTop: '20px',
    marginBottom: '30px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    wordBreak: 'break-word',
  },
  buttonContainer: {
    marginBottom: '50px',
  },
  button: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    width: '80px',
    marginRight: '25px',
    textTransform: 'none !important',
  },
  buttonError: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    width: '80px',
    marginRight: '25px',
    backgroundColor: `${buttonRed}`,
    color: `${white} !important`,
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: `${buttonRedHover}`,
    },
  },
  line: {
    marginLeft: '-50px',
    marginRight: '-50px',
    color: `${lightGray}`,
  },
}));

const Job = ({
  job,
  onUpdateJobStatus,
  onDeleteJob,
}) => {
  const classes = useStyles();

  const deleteJob = () => {
    confirmAlert({
      title: 'Are you sure you want to delete this job?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onDeleteJob(job.id),
        },
        {
          label: 'No',
          onClick: () => console.log('Job NOT deleted', job.id),
        },
      ],
    });
  };

  const updateJobStatus = () => onUpdateJobStatus(job.id, !job.jobStatus);

  return (
    <div className={`${classes.jobRow} ${!job.jobStatus ? classes.jobRowInactive : ''}`} key={job.id}>
      <div className={classes.titleContainer}>
        <p className={classes.rowEntryTitle}>{job.title}</p>
        <div className={`${classes.rowTitleLine} ${!job.jobStatus ? classes.jobLineInactive : ''}`} />
      </div>
      <form className={classes.radioContainer}>
        <input
          type="radio"
          name="job-status"
          value="active"
          checked={job.jobStatus}
          onChange={updateJobStatus}
        />
        <span className={classes.radioText}>Active</span>
        <input
          type="radio"
          name="job-status"
          value="inactive"
          checked={!job.jobStatus}
          onChange={updateJobStatus}
        />
        <span className={classes.radioText}>Inactive</span>
      </form>

      <div className={classes.jobRowText}>
        <p>{job.descriptionTitle}</p>
      </div>

      <div className={classes.buttonContainer}>
        <Link to={`edit-job/${job.id}`} style={{ textDecoration: 'none' }}>
          <Button variant="contained" className={classes.button} type="button">Edit</Button>
        </Link>
        <Button className={classes.buttonError} variant="contained" type="button" onClick={deleteJob}>Delete</Button>
      </div>
      <hr className={classes.line} />
    </div>
  );
};

export default Job;
