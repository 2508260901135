import { connect } from 'react-redux';
import { onUpdateField, onPasswordChange, AUTH } from '../../redux/state';
import ChangePasswordForm from './ChangePasswordForm';

const validatePasswordReset = (password, confirmPassword) => {
  if (!password || password.length < 8) {
    return 'Password must have at least 8 characters!';
  }
  if (!confirmPassword || password !== confirmPassword) {
    return 'Confirm password must match password';
  }

  return '';
};

export const mapStateToProps = (state) => {
  const {
    resetPassword,
    resetConfirmPassword,
    user,
  } = state[AUTH];

  const error = validatePasswordReset(resetPassword, resetConfirmPassword);

  return {
    user,
    password: resetPassword,
    confirmPassword: resetConfirmPassword,
    error,
  };
};

export const mapDispatchToProps = {
  onUpdateField,
  onPasswordChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
