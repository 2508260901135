import { connect } from 'react-redux';
import { AUTH, onLogin, onUpdateField } from '../../redux/state';
import Login from './Login';

export const mapStateToProps = (state) => {
  const {
    loginEmail,
    loginPassword,
    user,
    isPasswordChangeRequired,
    wrongCredentials,
  } = state[AUTH];

  const invalidCredentials = !(loginEmail.length > 0 && loginPassword.length > 0);

  return {
    email: loginEmail,
    password: loginPassword,
    invalidCredentials,
    user,
    isPasswordChangeRequired,
    wrongCredentials,
  };
};

export const mapDispatchToProps = {
  onLogin,
  onUpdateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
