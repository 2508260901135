import { all } from 'redux-saga/effects';
import sagas from './sagas';

function* rootSaga() {
  yield all([
    ...sagas,
    // Add more sagas here
  ]);
}

export default rootSaga;
