// Unused Copy-pasted as placeholder
import { createTheme } from '@material-ui/core/styles';
import {
  neusoftBlue,
  white,
  textColor,
  bannerColor,
  secondTextColor,
} from '../colors';

const theme = createTheme({
  palette: {
    primary: { main: white },
    text: {
      primary: textColor,
      secondary: secondTextColor,
    },
    secondary: { main: bannerColor },
    info: { main: neusoftBlue },
  },
  typography: {
    h3: {
      cursor: 'pointer',
      fontSize: '1.375rem',
      '@media(min-width:160em)': {
        fontSize: '1.5rem',
      },
      '@media(min-width:200em)': {
        fontSize: '1.8rem',
      },
      '@media(min-width:250em)': {
        fontSize: '2rem',
      },
      fontStyle: 'normal',
      fontWeight: '500',
      color: textColor,
    },
    h4: {
      cursor: 'pointer',
      fontWeight: 'normal',
      fontSize: '1.125rem',
      '@media(min-width:0m)': {
        fontSize: '50%',
      },
      '@media(min-width:48em)': {
        fontSize: '55%',
      },
      '@media(min-width:64em)': {
        fontSize: '1.8rem',
      },
      '@media(min-width:160em)': {
        fontSize: '1.3rem',
      },
      '@media(min-width:200em)': {
        fontSize: '1.6rem',
      },
      '@media(min-width:250em)': {
        fontSize: '1.8rem',
      },
    },
    p: {
      fontSize: '1.125rem',
      '@media(min-width:160em)': {
        fontSize: '1.3rem',
      },
      '@media(min-width:200em)': {
        fontSize: '1.5rem',
      },
      '@media(min-width:250em)': {
        fontSize: '1.7rem',
      },
    },
    body1: {
      color: textColor,
    },
  },
  breakpoints: {
    values: {
      xs: '30em',
      s: '48em',
      m: '64em',
      l: '85.375em',
      xl: '120em',
      xxl: '160em',
    },
  },
});

export default theme;
