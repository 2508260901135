import { connect } from 'react-redux';
import { onUpdateJobStatus, onDeleteJob } from '../../redux/state';
import Job from './Job';

export const mapStateToProps = (state) => {
  const {
    jobs,
  } = state;

  return {
    jobs,
  };
};

export const mapDispatchToProps = {
  onUpdateJobStatus,
  onDeleteJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(Job);
