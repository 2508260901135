import { connect } from 'react-redux';
import { onFetchJobs } from '../../redux/state';
import Jobs from './Jobs';

export const mapStateToProps = (state) => {
  const {
    jobs,
  } = state;

  return {
    jobs,
  };
};

export const mapDispatchToProps = {
  onFetchJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
