import { connect } from 'react-redux';
import {
  JOB_DATA,
  onFetchJobById,
  onUpdateField,
  onAddJob,
  onUpdateJob,
  resetJobState,
} from '../../redux/state';
import AddJob from './AddJob';

export const mapStateToProps = (state) => {
  const {
    shouldRedirect,
    isEnabled,
    id,
    title,
    descriptionTitle,
    descriptionText,
    jobLocation,
    referenceNumber,
  } = state[JOB_DATA];

  return {
    id,
    shouldRedirect,
    isEnabled,
    title,
    descriptionTitle,
    descriptionText,
    jobLocation,
    referenceNumber,
  };
};

export const mapDispatchToProps = {
  onFetchJobById,
  onUpdateField,
  onAddJob,
  onUpdateJob,
  resetJobState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJob);
