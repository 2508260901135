export const neusoftBlue = '#005BAC';
export const black = '#000000';
export const textColor = '#00002E';
export const bannerColor = '#F1F1F1';
export const secondTextColor = '#00002EA0';

export const white = '#FFFFFF';
export const neuBlue = '#00509b';
export const dividerGray = '#D7D7D7';
export const inactiveGray = '#9b9b9b';
export const inputDarkGray = '#333333';
export const lightGray = '#f5f5f5';
export const errorRed = '#ff3d67';
export const buttonRed = '#dc3545';
export const buttonRedHover = '#c82333';
export const successGreen = '#28a745';
export const successLightGreen = '#70c383';
