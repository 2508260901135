// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import { API, Auth } from 'aws-amplify';
import { channel } from 'redux-saga';
import {
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  AUTH,
  defaultJobDataState,
  JOB_DATA,
  onUpdateField,
  ON_ADD_JOB,
  ON_CHANGE_PASSWORD,
  ON_DELETE_JOB,
  ON_FETCH_JOBS,
  ON_FETCH_JOB_BY_ID,
  ON_LOGIN,
  ON_LOGOUT,
  ON_UPDATE_JOB,
  ON_UPDATE_JOB_STATUS,
  setJobData,
  setJobs,
} from './state';

export const callbackChannel = channel();
export const getState = (state) => state;
export function* passCallbackResults(action) {
  yield put(action);
}

export function* fetchJobs() {
  try {
    const { Items } = yield API.get('v1', '/jobs');
    yield put(setJobs(Items));
  } catch (e) {
    console.log(e);
  }
}

export function* updateJobStatus({ id, newStatus }) {
  try {
    yield API.put('v1', `/status/job/${id}`, {
      body: {
        jobStatus: newStatus,
      },
    });
    yield call(fetchJobs);
  } catch (e) {
    console.log(e);
  }
}

export function* login({ email, password }) {
  try {
    const user = yield Auth.signIn(email, password);
    yield put(onUpdateField(AUTH, 'user', user));
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(onUpdateField(AUTH, 'isPasswordChangeRequired', true));
    } else {
      yield put(onUpdateField(AUTH, 'isAuthenticated', true));
    }
  } catch (e) {
    yield put(onUpdateField(AUTH, 'wrongCredentials', true));
    console.log(e);
  }
}

export function* logout() {
  try {
    yield Auth.signOut({ global: true });
  } catch (e) {
    console.log(e);
  }
}

export function* changePassword({ user, password }) {
  try {
    yield Auth.completeNewPassword(user, password);
    yield put(onUpdateField(AUTH, 'isAuthenticated', true));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchJobById({ id }) {
  try {
    const { Item } = yield API.get('v1', `/job/${id}`);
    yield put(setJobData(
      {
        ...defaultJobDataState,
        ...Item,
        isEnabled: Item.jobStatus,
      },
    ));
  } catch (e) {
    console.log(e);
  }
}

export function* addJob({ job }) {
  try {
    yield API.post('v1', '/job', {
      body: job,
    });
    yield put(onUpdateField(JOB_DATA, 'shouldRedirect', true));
    yield put(setJobData({ ...defaultJobDataState }));
  } catch (e) {
    console.log(e);
  }
}

export function* updateJob({ job }) {
  try {
    yield API.put('v1', '/job', {
      body: job,
    });
    yield put(onUpdateField(JOB_DATA, 'shouldRedirect', true));
    yield put(setJobData({ ...defaultJobDataState }));
    yield call(fetchJobs);
  } catch (e) {
    console.log(e);
  }
}

export function* deleteJob({ id }) {
  try {
    yield API.del('v1', `/job/${id}`);
    yield call(fetchJobs);
    console.log('DELETE ', id);
  } catch (e) {
    console.log(e);
  }
}

export function* watchCallbackChannel() {
  yield takeEvery(callbackChannel, passCallbackResults);
}

export function* watchOnFetchJobs() {
  yield takeEvery(ON_FETCH_JOBS, fetchJobs);
}

export function* watchOnUpdateJobStatus() {
  yield takeEvery(ON_UPDATE_JOB_STATUS, updateJobStatus);
}

export function* watchOnLogin() {
  yield takeEvery(ON_LOGIN, login);
}

export function* watchOnChangePassword() {
  yield takeEvery(ON_CHANGE_PASSWORD, changePassword);
}

export function* watchOnFetchJobById() {
  yield takeEvery(ON_FETCH_JOB_BY_ID, fetchJobById);
}

export function* watchOnAddJob() {
  yield takeEvery(ON_ADD_JOB, addJob);
}

export function* watchOnUpdateJob() {
  yield takeEvery(ON_UPDATE_JOB, updateJob);
}

export function* watchOnDeleteJob() {
  yield takeEvery(ON_DELETE_JOB, deleteJob);
}

export function* watchOnLogout() {
  yield takeEvery(ON_LOGOUT, logout);
}

export default [
  fork(watchCallbackChannel),
  fork(watchOnFetchJobs),
  fork(watchOnUpdateJobStatus),
  fork(watchOnLogin),
  fork(watchOnFetchJobById),
  fork(watchOnAddJob),
  fork(watchOnUpdateJob),
  fork(watchOnDeleteJob),
  fork(watchOnLogout),
  fork(watchOnChangePassword),
];
